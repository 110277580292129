import { Maybe } from "@/generated/graphql";
import { PermissionsEnum } from "@/generated/graphql";

export enum Rights {
  all = "Есть",
  none = "Нет",
}

/* 
  CATALOGS Справочники
  USERS Пользователи
  EVENTS Мероприятия
  ADMINISTRATION Администрирование
  CONTRACTORS Поставщики
*/

enum Entities {
  USERS = "USERS",
  CATALOGS = "CATALOGS",
  events = "EVENTS",
  ADMINISTRATION = "ADMINISTRATION",
  CONTRACTORS = "CONTRACTORS",
}

export type Role = {
  id: string;
  comment: Maybe<string> | undefined;
  name: PermissionsEnum;
};

export type EntityRole = {
  [key in Entities]?: [Role];
};
