





































































import { Header } from "@/types/table";
import { RoleSections } from "@/types/core";
import { Rights, EntityRole } from "@/types/roles";
import { Vue, Component, Prop, Watch, ModelSync } from "vue-property-decorator";
import { Permission, PermissionsEnum } from "@/generated/graphql";

@Component({
  name: "PermissionsComponent",
})
export default class Permissions extends Vue {
  @Prop([]) readonly permissions!: Permission[];
  @Prop(Boolean) readonly showCheckbox!: boolean;
  @ModelSync("tempSelected", "select", { type: [] }) selected!: Permission[];

  protected tab: number = 0;
  protected readonly headers: Header[] = [
    { text: "ID", value: "id" },
    { text: "Название", value: "comment" },
  ];

  protected items: EntityRole[] = [
    {
      USERS: [
        {
          id: "1",
          comment: "",
          name: PermissionsEnum["CreateUser"],
        },
      ],
    },
  ];

  rights: Rights[] = [Rights["none"], Rights["all"]];

  loading: boolean = false;

  @Watch("permissions")
  onPermissionChange(): void {
    this.items = this.permissions.reduce(
      (previousValue: Permission | any, currentValue: Permission | any) => {
        const subsystem = currentValue["subsystem"];
        if (!previousValue[subsystem]) {
          previousValue[subsystem] = [
            {
              id: currentValue["id"],
              comment: currentValue["comment"],
              name: currentValue["name"],
            },
          ];
        } else {
          previousValue[subsystem].push({
            id: currentValue["id"],
            comment: currentValue["comment"],
            name: currentValue["name"],
          });
        }
        return previousValue;
      },
      {}
    );
  }

  @Watch("selected")
  onSelect(val: Permission[]): void {
    this.$emit("onSelect", val);
  }

  role(type: keyof typeof RoleSections) {
    return RoleSections[type];
  }
}
