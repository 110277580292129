










































































































import PermissionsComponent from "@/components/widgets/roles/Permissions.vue";
import { Component, Watch, Mixins } from "vue-property-decorator";
import { apolloClient } from "@/core/apollo";
import { Permissions } from "@/graphql/queries/Permissions.graphql";
import {
  CreateRoleMutation,
  CreateRoleMutationVariables,
  Permission,
  PermissionsQuery,
  RoleByIdQuery,
  UpdateRoleMutation,
  UpdateRoleMutationVariables,
} from "@/generated/graphql";
import {
  createRole,
  RoleById,
  updateRole,
} from "@/graphql/queries/Roles.graphql";
import { Routes } from "@/types/core";
import { BaseView } from "@/core/UI/Views/BaseView";
import userRolesMixin from "@/mixins/userRoles";

@Component({
  components: { PermissionsComponent },
  apollo: {
    role: {
      query: RoleById,
      fetchPolicy: "cache-and-network",
      variables(this: RoleView) {
        return { id: this.$route.params.id };
      },
      skip(this: RoleView) {
        return this.creationMode;
      },
    },
  },
})
export default class RoleView extends Mixins(userRolesMixin, BaseView) {
  roleName: string = "";
  roleComment: string = "";
  permissions: Permission[] = [];
  selectedRoles: Permission[] = [];
  valid: boolean = false;
  errors: string = "Роль обязательна";
  saved: boolean = false;
  error: boolean = false;
  errorText: string = "";

  private role: RoleByIdQuery["role"] = null;
  protected Routes = Routes;

  get creationMode(): boolean {
    return !this.$route.params.id;
  }
  public created(): void {
    if (!this.VIEW_ROLE && !this.creationMode)
      this.$router.push({ name: Routes.noRights });
    if (this.creationMode && !this.CREATE_ROLE)
      this.$router.push({ name: Routes.noRights });
  }
  async mounted(): Promise<void> {
    await this.getData();
  }

  public updated(): void {
    this.valid = !(
      !this.selectedRoles.length ||
      !this.roleName ||
      !this.roleComment
    );
  }
  @Watch("selectedRoles")
  onSelect(val: Permission[]): void {
    if (!val.length) {
      this.errors = "Роль обязательна";
      this.valid = false;
    } else {
      this.errors = "";
    }
  }
  @Watch("role", { deep: true, immediate: true })
  onRole(): void {
    if (this.creationMode) return;
    const role = this.role;
    if (role) {
      this.roleName = role.name;
      this.roleComment = role.comment || "";
      this.selectedRoles = [...role.permissions];
    }
  }
  async getData(): Promise<void> {
    try {
      const { data } = await apolloClient.query<PermissionsQuery>({
        query: Permissions,
      });
      this.permissions = [...data.permissions];
    } catch (err) {
      //@ts-ignore
      console.log(err.graphQLErrors);
    }
  }

  protected loading = false;
  protected async save(): Promise<void> {
    const permissions = this.selectedRoles.map((value) => value.name);
    try {
      this.loading = true;
      if (this.creationMode) {
        await apolloClient.mutate<
          CreateRoleMutation,
          CreateRoleMutationVariables
        >({
          mutation: createRole,
          variables: {
            input: {
              comment: this.roleComment,
              name: this.roleName,
              permissions,
            },
          },
        });
      } else {
        const updateRoleInput: UpdateRoleMutationVariables["input"] = {
          id: this.$route.params.id,
          permissions,
        };

        if (this.roleComment !== this.role?.comment) {
          updateRoleInput.comment = this.roleComment;
        }

        if (this.roleName !== this.role?.name) {
          updateRoleInput.name = this.roleName;
        }

        await apolloClient.mutate<
          UpdateRoleMutation,
          UpdateRoleMutationVariables
        >({
          mutation: updateRole,
          variables: {
            input: updateRoleInput,
          },
          refetchQueries: [
            {
              query: RoleById,
              variables: { id: this.$route.params.id },
            },
          ],
        });
      }
      this.saved = true;
      this.isShowAskForConfirmToSave = false;
    } catch (err: any) {
      this.error = true;
      this.errorText = err;
      throw err;
    } finally {
      this.loading = false;
    }
  }

  protected isShowAskForConfirmToSave = false;
  protected isShowAskForConfirmToCancel = false;

  protected askForConfirmToSave(): void {
    this.isShowAskForConfirmToSave = true;
  }

  protected askForConfirmToCancel(): void {
    this.isShowAskForConfirmToCancel = true;
  }
}
